.header {
  height: 190px;
  margin-top: 48px;
  width: 40px;
  position: relative;
}

.header span {
  font-family: BallonCaps;
  font-size: 64px;
  transform-origin: bottom;
  position: absolute;
  height: 170px;
}

.c1 {
  transform: rotate(-84deg);
  color: #2990F4;
}

.c2 {
  transform: rotate(-72deg);
  color: #85FB67;
}

.c3 {
  transform: rotate(-60deg);
  color: #BF57FE;
}

.c4 {
  transform: rotate(-48deg);
  color: #FA6765;
}

.c5 {
  transform: rotate(-36deg);
  color: #2990F4;
}

.c6 {
  transform: rotate(-24deg);
  color: #85FB67;
}

.c7 {
  transform: rotate(-12deg);
  color: #BF57FE;
}

.c8 {
  transform: rotate(0deg);
  color: #FA6765;
}

.c9 {
  transform: rotate(12deg);
  color: #2990F4;
}

.c10 {
  transform: rotate(24deg);
  color: #85FB67;
}

.c11 {
  transform: rotate(36deg);
  color: #BF57FE;
}

.c12 {
  transform: rotate(48deg);
  color: #FA6765;
}

.c13 {
  transform: rotate(60deg);
  color: #2990F4;
}

.c14 {
  transform: rotate(72deg);
  color: #85FB67;
}

.c15 {
  transform: rotate(84deg);
  color: #BF57FE;
}