.buttonContainer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
}

.button {
  background-color: #1e90ff;
  border: none;
  padding: 15px 32px;
  text-align: center;
  font-size: 16px;
  color: white;
  border-radius: 12px;
  cursor: pointer;
  transition-duration: 0.4s;
  width: auto;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #2e7ecf;
    color: white;
  }
}

.closeButton {
  background-color: #ff4646;
  border: none;
  padding: 4px;
  text-align: center;
  font-size: 16px;
  color: white;
  border-radius: 100%;
  cursor: pointer;
  transition-duration: 0.4s;
  width: auto;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #b42525;
    color: white;
  }
}

.page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
}

.closeButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.credits {
  text-align: center;
  margin-bottom: 8px;
}

.music {
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
}