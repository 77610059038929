.root {
  margin: auto;
  margin-bottom: 100px;
  margin-top: 32px;
  padding: 16px;
  color: white;
  max-width: 800px;
  display: flex;
  justify-content: center;
}

.startButton {
  background-color: #4CAF50;
  border: none;
  padding: 15px 32px;
  text-align: center;
  font-size: 16px;
  color: white;
  border-radius: 12px;
  cursor: pointer;
  transition-duration: 0.4s;
  width: auto;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #3e8e41;
    color: white;
  }
}

.secondSection {
  margin-top: 16px;
}
